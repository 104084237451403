<script setup lang="ts">
useHead({
  title: '404 - Playermon Page'
})
</script>

<template>
  <div
    class="container max-w-screen-lg py-10 md:py-40 mx-auto px-5 md:px-0 text-smoke-white min-h-fit md:min-h-screen"
  >
    <p class="text-9xl font-bold">
      404
    </p>
    <p class="text-5xl">
      This page doesn't exist.
    </p>
  </div>
</template>
